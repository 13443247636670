$(document).ready(function(){
	
	resizeSetHeight()

	introAnimation()
	audioControlHoverAnimation()
	audioControls()

	randomStartTime()
	flurDuplicateVideos()

	raumNav()

	// wortDuplicateText()
	wortHighlightReading()

	fetchImgData()
	// imgDescription()
	slideshowNav()

	// clickFunkzeichen()
	// displayFunkzeichen()



	var resizeTimer;

	$(window).resize(function () {

		clearTimeout(resizeTimer);

		resizeTimer = setTimeout(function () {

			resizeSetHeight()

		}, 200);

	})

})